import React from "react"
import { useSelector } from "react-redux"
import { message, Button } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { format, formatValue, truncate } from "@/utils"
import { SVGFiles, SVGCheckCircled } from "@/svg"
import * as style from "./style.module.scss"

const Pools = () => {
  const pools = useSelector((state) => state.settings.pools)

  return (
    <div>
      <h5 className="mb-4">TADA Pools</h5>
      <div className="row">
        {pools?.pools?.map((pool, index) => {
          console.log(pool)
          const isSaturated = pool.data.total_stake > 60000000 * 1000000
          return (
            <div className="col-12 col-sm-6" key={index}>
              <div className={style.pool}>
                <span className="badge">
                  {isSaturated ? "SATURATED" : "ACTIVE"}
                </span>
                <strong className={style.poolTicker}>
                  {formatValue(pool.data.ticker_orig)}
                </strong>
                <div className={style.poolItem}>
                  <div className={style.poolLabel}>Pool Id</div>
                  <div className={style.poolValue}>
                    <CopyToClipboard
                      text={pool.data.pool_id_bech32}
                      onCopy={() =>
                        message.success("Pool ID copied successfully")
                      }
                    >
                      <strong className="cursor-pointer">
                        {truncate(pool.data.pool_id_bech32, 12)}
                        <span className="icn ms-2">
                          <SVGFiles />
                        </span>
                      </strong>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className={style.poolItem}>
                  <div className={style.poolLabel}>Pool Hash</div>
                  <div className={style.poolValue}>
                    <CopyToClipboard
                      text={pool.data.pool_id}
                      onCopy={() =>
                        message.success("Pool Hash copied successfully")
                      }
                    >
                      <strong className="cursor-pointer">
                        {truncate(pool.data.pool_id, 12)}
                        <span className="icn ms-2">
                          <SVGFiles />
                        </span>
                      </strong>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className={style.poolItem}>
                      <div className={style.poolLabel}>Saturation</div>
                      <div className={style.poolValue}>
                        <strong>
                          {formatValue(
                            (pool.data.saturated * 100).toFixed(2),
                            "%"
                          )}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={style.poolItem}>
                      <div className={style.poolLabel}>TADA Rewards</div>
                      <div className={style.poolValue}>
                        <span className="icn mt-2">
                          <SVGCheckCircled />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={style.poolItem}>
                      <div className={style.poolLabel}>Fee Margin</div>
                      <div className={style.poolValue}>
                        <strong>{formatValue(pool.data.tax_ratio, "%")}</strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={style.poolItem}>
                      <div className={style.poolLabel}>Delegators</div>
                      <div className={style.poolValue}>
                        <strong>{formatValue(pool.data.delegators)}</strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={style.poolItem}>
                      <div className={style.poolLabel}>Live Stake</div>
                      <div className={style.poolValue}>
                        <strong>
                          {formatValue(
                            format(pool.data.total_stake / 1000000),
                            <sup> ADA</sup>
                          )}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={style.poolItem}>
                      <div className={style.poolLabel}>Blocks Lifetime</div>
                      <div className={style.poolValue}>
                        <strong>
                          {formatValue(pool.data.blocks_lifetime)}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <Button
                    href="https://app.tadawallet.io/#/stake/"
                    target="_blank"
                    rel="noopener noreferrer"
                    type="primary"
                    className={`tada__btn tada__btn--success tada__btn--small w-100 ${
                      isSaturated && "tada__btn--disabled"
                    }`}
                  >
                    <strong>{isSaturated ? "Saturated" : "Delegate"}</strong>
                  </Button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Pools
